import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Constent } from '../Assets/Constants/Constent';
import '../Assets/CSS/LoginPage.css';
import { OtherImages } from '../Assets/Images/CelcomOtherImages';
import { resetCartData } from '../ReduxFeature/StateFeatures/CartDataState';
import { resetInvData } from '../ReduxFeature/StateFeatures/InventoryDataState';
import { resetLovData } from '../ReduxFeature/StateFeatures/LovDataState';
import { resetTrackOrderData } from '../ReduxFeature/StateFeatures/TrackOrderDataState';
import { resetData } from '../ReduxFeature/StateFeatures/UserDataState';
import { resetWorkOrderData } from '../ReduxFeature/StateFeatures/WorkOrderDataState';
import { UserAgentApplication } from 'msal';
import { setLoginUserName, setLoginUserToken } from '../ReduxFeature/StateFeatures/B2CDataState';
import { ApiConstant } from '../Assets/Constants/ApiConstants';
import Footer from '../Components/Footer/Footer';

export const LoginPage = ({ globalState, setGlobalState, setUserRole }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const b2cDataObj = useSelector((state) => state.b2cDataInfo);

  const { REACT_APP_B2C_CLIENT_ID, REACT_APP_B2C_AUTHORITY, REACT_APP_B2C_VALID_AUTHORITY,
    REACT_APP_B2C_KNOWN_AUTHORITY, REACT_APP_B2C_SCOPES, REACT_APP_B2C_SESSION_ID, REACT_APP_B2C_CHANNELNAME,
    REACT_APP_B2C_RESPONSEMODE, REACT_APP_B2C_NONCE, REACT_APP_B2C_PROMPT, REACT_APP_B2C_P } = process.env;
  const reSetState = () => {
    dispatch(resetData());
    dispatch(resetCartData());
    dispatch(resetWorkOrderData());
    dispatch(resetLovData());
    dispatch(resetInvData());
    dispatch(resetTrackOrderData());
    setUserRole("");
  };


  useEffect(() => {
    setGlobalState({ ...globalState, authLoaderPageState: false });
    reSetState();
  }, []);

  useEffect(() => {
    if (globalState?.authLoaderPageState) {
      navigate('/auth');
    }
  }, [globalState?.authLoaderPageState]);
  /*---------- B2C Function ----------- */
  const authonticationFun = (error, response) => {
    if (response) {
      console.log("Inside Login response", response);
      dispatch(setLoginUserName(response?.idTokenClaims?.name));
      dispatch(setLoginUserToken(response?.idToken?.rawIdToken));

      console.log("name",response?.idTokenClaims?.name);
      console.log("rawIdToken",response?.idToken?.rawIdToken);

      setGlobalState({ ...globalState, authLoaderPageState: true });
      navigate('/auth');
    }
    if (error) {
      console.log("Inside Login page error", error);
      try {
        console.log("Inside Loginpage try block");
        response?.idTokenClaims?.name?.length > 0 &&
          dispatch(setLoginUserName({
            name: response?.idTokenClaims?.name,
            token: response?.idToken?.rawIdToken
          }));
      } catch (err) { console.log("Inside Login page catch block",err);}

      setGlobalState({ ...globalState, authLoaderPageState: false });
      navigate('/');
    }
  }

  /*----------------- B2C Instance -------------- */
  const b2cInstance = new UserAgentApplication({
    auth: {
      clientId: REACT_APP_B2C_CLIENT_ID,
      authority: REACT_APP_B2C_AUTHORITY,
      // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      knownAuthorities: [REACT_APP_B2C_KNOWN_AUTHORITY],
      response_mode: REACT_APP_B2C_RESPONSEMODE,
      redirectUri: ApiConstant.INSTALLER_PORAL_DOMAIN_NAME,
      validateAuthority: REACT_APP_B2C_VALID_AUTHORITY
    },
    cache: {
      // cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  });
  console.log(b2cInstance)
  b2cInstance.handleRedirectCallback(authonticationFun);

  return (
    <div className='login-page-starter-section'>
      <div className="col-12 p-0 wrapper-login-page-section">
        <div className="col-xxl-8 col-md-11 col-sm-12 p-0 login-page-content-section">
          <div className="col-xxl-6 col-md-6 col-sm-0 p-0">
            <img src={OtherImages.CelcomBuildingImg} alt="" />
          </div>
          <div className="col-xxl-6 col-md-6 col-sm-12 p-0">
            <div className="login-page-main-content-section">
              <div className="login-page-logo mb-5">
                <img src={OtherImages.CelcomLogo} alt="" />
              </div>
              <div className="login-page-msg mb-3">
                {Constent.LOGIN_MESSAGE1}
              </div>
              <div className="login-page-msg">
                {Constent.LOGIN_MESSAGE2}
              </div>
              <div className="login-page-msg-under-line mb-6"></div>

              <div className="col-xxl-6 col-lg-6 col-md-10 col-sm-10 p-0 celcom-default-btn login-page-btn" onClick={() => {
                if (b2cDataObj?.loginUserName?.length > 0) {
                  setGlobalState({ ...globalState, authLoaderPageState: true });
                  console.log(authLoaderPageState);
                  console.log(globalState);
                } else {
                  b2cInstance.loginRedirect(
                    {
                      scopes: [
                        REACT_APP_B2C_SCOPES
                      ],
                      prompt: REACT_APP_B2C_PROMPT,
                      nonce: REACT_APP_B2C_NONCE,
                      responseMode: REACT_APP_B2C_RESPONSEMODE,
                      extraQueryParameters: {
                        channelName: REACT_APP_B2C_CHANNELNAME,
                        sessionID: REACT_APP_B2C_SESSION_ID, p: REACT_APP_B2C_P
                      }
                    }
                  );
                }
              }}>
                {b2cDataObj?.loginUserName?.length > 0 ? "Sign In" : "Login"}
              </div>
            </div>
          </div>
        </div>
        <Footer loginPage={true}/>
      </div>
    </div>
  )
}
