import React, { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-date-picker";
import { useSelector, useDispatch } from "react-redux";
// import {
//   GenericLovCalls,
//   headers,
//   PostTest,
//   TrackStockList,
// } from "../../Api/ApiCalls";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { Constent } from "../../Assets/Constants/Constent";
import { BodyBreadcrumbs } from "../../Components/Common/BodyBreadcrumbs";
import {
    CalendarIcon,
    DownloadIcon,
    SearchIcon,
} from "../../Assets/Images/CelcomSvgImages";
import { ToastContainer, toast } from "react-toastify";
import UploadDocumentModal from "../../Components/Modals/UploadDocumentModal";
import UploadScreen from "./UploadScreen";
import ViewScreen from "./ViewScreen";
import { Header } from "../../Components/Header/Header";
import SupervisorSidebar from "../../Components/MainBody/Sidebar/SupervisorSidebar";
import ViewCards from "./ViewCards";

const PIDUpload = ({ globalState, setGlobalState }) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const [isSearch, setIsSearch] = useState(false);
    const [viewCardsData,setViewCardsData] = useState();
    const [pageState, setPageState] = useState({
        activePill : Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM1,
        workOrderFromDate: "",
        fromDateError: "",
        workOrderToDate: "",
        toDateError: ""
    });
    return (
        <>
        <div className="page-start-section">
      <Header
        userDataObj={userDataObj}
        globalState={globalState}
        setGlobalState={setGlobalState}
        tab7={true}
      />
      <div
        className={
          globalState?.sidebarState
            ? "body-start-section sidebar-active-section"
            : "body-start-section"
        }
      >
         {/* Sidebar */}
         <SupervisorSidebar
          userDataObj={userDataObj}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
            <div className="workorder-header-start-section">
                <div className="wrapper-workorder-header-section">
                    <BodyBreadcrumbs
                        activeItem={Constent.HEADER_MENU_ITEM67}
                    />
                    <div
                        className="inventory-header-pill-section mb-3"
                        data-aos="fade-right"
                        data-aos-easing="ease-in-out"
                        data-aos-delay="100"
                    >

                        <div className="nav-responsive">
                            <div className="nav nav-pills nav-child-2 position-relative text-center defualt-custom-pill-transition">
                                <a
                                    className={
                                        pageState?.activePill ===
                                            Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM1
                                            ? "nav-link col active"
                                            : "nav-link col"
                                    }
                                    onClick={() => {
                                        setPageState({
                                            ...pageState,
                                            activePill:
                                                Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM1,
                                        });
                                    }}
                                    data-toggle="tab"
                                    href={"#" + Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM1}
                                >
                                    {Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM1}
                                </a>
                                <a
                                    className={
                                        pageState?.activePill ===
                                            Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM2
                                            ? "nav-link col active"
                                            : "nav-link col"
                                    }
                                    onClick={() => {
                                        setPageState({
                                            ...pageState,
                                            activePill:
                                                Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM2,
                                        });
                                    }}
                                    data-toggle="tab"
                                    href={"#" + Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM2}
                                >
                                    {Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM2}
                                </a>
                                <hr className="tab-active defualt-custom-pill-transition" />
                            </div>
                        </div>

                    </div>
                    <div>
                        {
                            pageState?.activePill === Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM1 ?
                                <UploadScreen/>
                            :
                                <ViewScreen
                                    pageState={pageState}
                                    setPageState={setPageState}
                                    setIsSearch={setIsSearch}
                                    setViewCardsData={setViewCardsData}
                                    isSearch={isSearch}
                                />
                        }
                    </div>
                </div>

            </div>
            {
                pageState?.activePill != Constent.Body_HEADER_PARTNERUPLOAD_PILL_ITEM1 && viewCardsData && (
                    <ViewCards
                         viewCardsData = {viewCardsData}
                    />
                )
            }
            </div>
            </div>
        </>
    )
};

export default PIDUpload;
