import axios from "axios";
import { ApiConstant } from "../Assets/Constants/ApiConstants";

export const api = axios.create({
  // baseURL: "https://jsonplaceholder.typicode.com/"
  baseURL: ApiConstant.INSTALLER_PORAL_DOMAIN_NAME,
});

export const loginHeader = ({ azToken = "" }) => {
  return {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    azToken: azToken,
  };
};

export const headers = (token = null) => {
  if (token === null) {
    return {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      "Cache-Control": "no-store",
    };
  }
  return {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    Authorization: `celcom ${token}`,
  };
};

api.interceptors.request.use((request) => {
  return request;
});

api.interceptors.response.use((response) => {
  return response?.data;
});

/* 




----------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------ Api calls ------------------------ */
/* ------------ Login ------------ */
export const Login = async (url, username = null, header = headers()) => {
  return api.post(url, { username: username }, header);
};
/* ------------ Dashbord - Calendar ------------ */
export const SupervisorCalendar = async (
  url,
  { monthYear = null, userId = null, limit = 500, offSet = 0 },
  header = headers()
) => {
  let request = {
    monthYear: monthYear,
    userId: userId,
    limit: limit,
    offSet: offSet,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Supervisor Summary ------------ */
export const SupervisorSummary = async (
  url,
  {
    monthYear = null,
    userId = null,
    limit = 500,
    offSet = 0,
    summaryDetailsFlag = false,
  },
  header = headers()
) => {
  let request = {
    monthYear: monthYear,
    userId: userId,
    limit: limit,
    offSet: offSet,
    summaryDetailsFlag: summaryDetailsFlag,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Installer Summary ------------ */
export const InstallerSummary = async (
  url,
  { monthYear = null, userId = null, limit = 500, offSet = 0 },
  header = headers()
) => {
  let request = {
    monthYear: monthYear,
    userId: userId,
    limit: limit,
    offSet: offSet,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Search Work Order ------------ */
export const WorkOrderPage = async (
  url,
  {
    fromDate = null,
    toDate = null,
    activityType = null,
    requestType = null,
    activitySubType = null,
    itemSubType = null,
    stsType = null,
    activityId = null,
    orderNumber = null,
    customerId = null,
    userId = null,
    limit = 500,
    offSet = 0,
  },
  header = headers()
) => {
  let request = {
    fromDate: fromDate,
    requestType: requestType,
    toDate: toDate,
    activityType: activityType,
    activitySubType: activitySubType,
    itemSubType: itemSubType,
    stsType: stsType,
    activityId: activityId,
    orderNumber: orderNumber,
    customerId: customerId,
    userId: userId,
    limit: limit,
    offSet: offSet,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Reassign Work Order ------------ */
export const ReassignWorkOrder = async (
  url,
  {
    itemId = null,
    reassignRemarks = null,
    trigerredBy = null,
    trigerredOn = null,
    userGroup = null,
    userId = null,
    telNumber = null,
    firstName = null,
    email = null,
    outletId = null,
    orderType = null,
    assignType = null,
    appointmentDate = null,
    revertBy = null,
    orderId = null,
  },
  header = headers()
) => {
  let request = {
    reassignActivityNoteRequestEntity: {
      itemId: itemId,
      reassignRemarks: reassignRemarks,
      trigerredBy: trigerredBy,
      trigerredOn: trigerredOn,
      userGroup: userGroup,
      userId: userId,
    },
    telNumber: telNumber,
    firstName: firstName,
    email: email,
    outletId: outletId,
    orderType: orderType,
    assignType: assignType,
    revertBy: revertBy,
    appointmentDate: appointmentDate,
    orderId: orderId,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Work Order Details ------------ */
export const WorkOrderDetails = async (
  url,
  { activityId = null, userId = null, outletId = null },
  header = headers()
) => {
  let request = {
    activityId: activityId,
    userId: userId,
    outletId: outletId,
  };
  return api.post(url, request, {
    headers: header,
  });
};

/* ------------ Work Order Details Installer Notes:  ------------ */
export const InstallerNotes = async (
  url,
  { orderId = null },
  header = headers()
) => {
  let request = {
    orderId: orderId,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Update Work Order Details ------------ */
export const UpdateWorkOrderDetails = async (
  url,
  {
    accTaskMapId = null,
    activityId = null,
    activitySubType = null,
    activityType = null,
    btuId = null,
    btuPortNo = null,
    cableLength = null,
    desiredInstallation = null,
    btuProductId = null,
    deviceDetailList = null,
    infrastructure = null,
    lattitude = null,
    location = null,
    longitude = null,
    meshDevice = null,
    ostProblemCause = null,
    ostResolution = null,
    remarks = null,
    returnedReason = null,
    streetName = null,
    installerContact = null,
    installerId = null,
    installerName = null,
    status = null,
    storeId = null,
    userId = null,
    workOrderSubType = null,
    workOrderType = null,
    statusUpdateDateTime = null,
    reservationId = null,
    ostDeviceSwapRequired = false,
    ostDeviceSwapActiveDevice = null,
    customerId = null,
    caseNumber = null,
    customerAccountId = null,
    subscriberId = null,
    btuCollected = null,
  },
  header = headers()
) => {
  const request = {
    accTaskMapId: accTaskMapId,
    activityId: activityId,
    activitySubType: activitySubType,
    activityType: activityType,
    installationModel: {
      btuId: btuId,
      btuPortNo: btuPortNo,
      btuProductId: btuProductId,
      btuCollected: btuCollected,
      cableLength: cableLength,
      desiredInstallation: desiredInstallation,
      deviceDetailList: deviceDetailList,
      infrastructure: infrastructure,
      lattitude: lattitude,
      location: location,
      longitude: longitude,
      meshDevice: meshDevice,
      ostProblemCause: ostProblemCause,
      ostResolution: ostResolution,
      remarks: remarks,
      returnedReason: returnedReason,
      streetName: streetName,
      ostDeviceSwapRequired: ostDeviceSwapRequired,
      ostDeviceSwapActiveDevice: ostDeviceSwapActiveDevice,
    },
    installerContact: installerContact,
    installerId: installerId,
    installerName: installerName,
    status: status,
    storeId: storeId,
    userId: userId,
    workOrderSubType: workOrderSubType,
    workOrderType: workOrderType,
    statusUpdateDateTime: statusUpdateDateTime,
    reservationId: reservationId,
    customerId: customerId,
    caseNumber: caseNumber,
    customerAccountId: customerAccountId,
    subscriberId: subscriberId,
  };

  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ EDMS  ------------ */
export const DocumentManagement = async (
  url,
  {
    fileType = null,
    documentType = null,
    attachmentName = null,
    base64Document = null,
    comments = null,
    workOrder_Id = null,
    UploadedBy = null,
  },
  header = headers(),
  options = null
) => {
  const request = {
    fileType: fileType,
    documentType: documentType,
    base64Document: base64Document,
    attachmentName: attachmentName,
    comments: comments,
    workOrder_Id: workOrder_Id,
    UploadedBy: UploadedBy,
  };
  return api.post(url, request, {
    headers: header,
    onUploadProgress: options,
  });
};
export const DocumentManagement1 = async (
  url,
  { fromDate = null, orderId = null, documentType = null },
  header = headers(),
  options = null
) => {
  return api.post(url, fromDate, {
    params: { documentType: documentType, orderId: orderId },
    headers: header,
    onUploadProgress: options,
  });
};
export const DownloadDocument = async (
  url,
  { documentId = null },
  header = headers(),
  options = null
) => {
  return api.get(url, {
    params: { documentRefId: documentId },
    headers: header,
    options: options,
  });
};

/* ----------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------ Track Order ------------------------ */
/* ------------ Track Order List ------------ */
export const TrackOrderList = async (
  url,
  {
    searchType = null,
    dealerCode = null,
    startDate = null,
    endDate = null,
    orderContext = null,
    orderStatus = null,
    orderNumber = null,
    customerId = null,
    srvcAttrKey = null,
    srvcAttrVal = null,
  },
  header = headers()
) => {
  let request = {
    searchType: searchType,
    dealerCode: dealerCode,
    startDate: startDate,
    endDate: endDate,
    orderContext: orderContext,
    orderStatus: orderStatus,
    orderNumber: orderNumber,
    customerId: customerId,
    srvcAttrKey: srvcAttrKey,
    srvcAttrVal: srvcAttrVal,
  };

  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Work Order Details ------------ */
export const TrackOrderDetails = async (
  url,
  { orderId = null, userId = null, outletId = null },
  header = headers()
) => {
  const request = {
    orderId: orderId,
    outletId: outletId,
    userId: userId,
  };
  return api.post(url, request, {
    headers: header,
  });
};

/* ----------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------ Manage Installer ------------------------ */
/* ------------ Get Installer ------------ */
export const GetInsatller = async (
  url,
  { supervisorId = null, outletId = null, district = null },
  header = headers()
) => {
  let request = {
    supervisorId: supervisorId,
    outletId: outletId,
    district: district,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Create Installer ------------ */
export const CreateInstaller = async (
  url,
  {
    companyName = null,
    district = null,
    email = null,
    firstName = null,
    id = null,
    idType = null,
    lastName = null,
    mobileNo = null,
    outletId = null,
    outletName = null,
    outletType = null,
    partnerId = null,
    supervisorId = null,
    userId = null,
  },
  header = headers()
) => {
  let request = {
    companyName: companyName,
    district: district,
    email: email,
    firstName: firstName,
    id: id,
    idType: idType,
    lastName: lastName,
    mobileNo: mobileNo,
    outletId: outletId,
    outletName: outletName,
    outletType: outletType,
    partnerId: partnerId,
    supervisorId: supervisorId,
    userId: userId,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Delete Installer ------------ */
export const InactiveInsatller = async (
  url,
  { userId = null, requestBy = null },
  header = headers()
) => {
  let request = {
    userId: userId,
    requestBy: requestBy,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Update Installer ------------ */
export const UpdateInsatller = async (
  url,
  {
    userNbr = null,
    accessLevel = null,
    partnerId = null,
    outletId = null,
    outletName = null,
    outletType = null,
    userId = null,
    companyName = null,
    firstName = null,
    lastName = null,
    id = null,
    brnid = null,
    email = null,
    mobileNo = null,
    idType = null,
    preferredLang = null,
    supervisorId = null,
    district = null,
  },
  header = headers()
) => {
  let request = {
    userNbr: userNbr,
    accessLevel: accessLevel,
    partnerId: partnerId,
    outletId: outletId,
    outletName: outletName,
    outletType: outletType,
    userId: userId,
    companyName: companyName,
    firstName: firstName,
    lastName: lastName,
    id: id,
    brnid: brnid,
    email: email,
    mobileNo: mobileNo,
    supervisorId: supervisorId,
    district: district,
    idType: idType,
    preferredLang: preferredLang,
  };

  return api.post(url, request, {
    headers: header,
  });
};

/* ----------------------------------------------------------------------------------------------------------------------------- */
/* ------------------------ Manage Inventory ------------------------ */
/* ------------ View Stock List ------------ */
export const ViewStockList = async (
  url,
  {
    deviceTypeId = null,
    inventoryItemTypeId = ApiConstant.INVENTORY_ITEM_TYPE_ID,
    itemLocationId = null,
    manufacturerId = null,
    userId = null,
    sapMaterialCode = null,
    itemDesc = null,
  },
  header = headers()
) => {
  let request = {
    stockAvailabilityCheckGenericAPIRequest: {
      sapMaterialCode: sapMaterialCode,
      itemDesc: itemDesc,
      deviceTypeId: deviceTypeId,
      inventoryItemTypeId: inventoryItemTypeId,
      itemLocationId: itemLocationId,
      manufacturerId: manufacturerId,
      userId: userId,
    },
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ View Stock Details ------------ */
export const ViewStockDetails = async (
  url,
  {
    recordRowStart = 0,
    recordRowEnd = 100,
    outletId = null,
    materialCode = null,
  },
  header = headers()
) => {
  let request = {
    recordRowStart: recordRowStart,
    recordRowEnd: recordRowEnd,
    outletId: outletId,
    materialCode: materialCode,
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Track Stock List ------------ */
export const TrackStockList = async (
  url,
  {
    dateFrom = null,
    dateTo = null,
    requestType = null,
    requestingStoreId = null,
    storeId = null,
    transferId = null,
    transferStatus = null,
    userId = null,
    approverName = null,
    ...abc
  },
  header = headers()
) => {
  let request = {
    transferListByDateRangeClientRequestDTO: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      requestType: requestType,
      requestingStoreId: requestingStoreId,
      storeId: storeId,
      transferId: transferId,
      transferStatus: transferStatus,
      userId: userId,
      approverName: approverName,
      ...abc,
    },
  };
  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Track Stock Details ------------ */
export const TrackStockDetails = async (
  url,
  { transferId = null },
  header = headers()
) => {
  let request = {
    transferId: transferId,
  };

  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Report Extraction ------------ */
export const ReportExtraction = async (
  url,
  {
    workOrderId = null,
    orderNo = null,
    customerId = null,
    fromDate = null,
    toDate = null,
    accessProviderDetail = null,
    appointmentType = null,
    userId = null,
    companyName = null,
    userRole = null,
  },
  header = headers()
) => {
  let request = {
    workOrderId: workOrderId,
    orderNo: orderNo,
    customerId: customerId,
    fromDate: fromDate,
    toDate: toDate,
    accessProvider: accessProviderDetail,
    appointmentType: appointmentType,
    userId: userId,
    companyName: companyName,
    userRole: userRole,
  };

  return api.post(url, request, {
    headers: header,
  });
};
/* ------------ Request Stock ------------ */
export const RequestStock = async (
  url,
  {
    comments = null,
    createdBy = null,
    listOfItemDetailsRequest = null,
    requestType = null,
    towId = null,
    transferFromStoreId = null,
    transferToStoreId = null,
  },
  header = headers()
) => {
  let request = {
    createStockTransferrequestRequestDTO: {
      comments: comments,
      createdBy: createdBy,
      listOfItemDetailsRequest: listOfItemDetailsRequest,
      requestType: requestType,
      towId: towId,
      transferFromStoreId: transferFromStoreId,
      transferToStoreId: transferToStoreId,
    },
  };
  return api.post(url, request, {
    headers: header,
  });
};

//-------------------TRANSFER STOCK-------------------
export const TransferStock = async (
  url,
  {
    comments = null,
    createdBy = null,
    listOfItemDetailsRequest = null,
    requestType = null,
    towId = null,
    transferFromStoreId = null,
    transferToStoreId = null,
  },
  header = headers()
) => {
  let request = {
    createStockTransferrequestRequestDTO: {
      comments: comments,
      createdBy: createdBy,
      listOfItemDetailsRequest: listOfItemDetailsRequest,
      requestType: requestType,
      towId: towId,
      transferFromStoreId: transferFromStoreId,
      transferToStoreId: transferToStoreId,
    },
  };
  return api.post(url, request, {
    headers: header,
  });
};

/* ------------ Return, Approve, Acknowledge Stock ------------ */
export const UpdateStock1 = async (
  url,
  {
    action = null,
    approvalUser = null,
    approverComments = null,
    receiveUser = null,
    receiverStore = null,
    senderStore = null,
    shipUser = null,
    sourceSystem = null,
    starterPackFlag = null,
    stockItem = null,
    storeId = null,
    transactionNo = null,
    transferId = null,
    acknowledgeDetails = null,
    ...abc
  },
  header = headers()
) => {
  let request = {
    action: action,
    approvalUser: approvalUser,
    approverComments: approverComments,
    receiveUser: receiveUser,
    receiverStore: receiverStore,
    senderStore: senderStore,
    shipUser: shipUser,
    sourceSystem: sourceSystem,
    starterPackFlag: starterPackFlag,
    stockItem: stockItem,
    storeId: storeId,
    transactionNo: transactionNo,
    transferId: transferId,
    acknowledgeDetails: acknowledgeDetails,
    ...abc,
  };
  return api.post(url, request, {
    headers: header,
  });
};

export const UpdateStockEmail1 = async (
  url,
  {
    requestDate = null,
    plantNo = null,
    requestId = null,
    customerName = null,
    CustomerContact = null,
    fromAddress = null,
    recipientContact = null,
    recipientName = null,
    toAddress = null,
    itemDetails = null,
    quantity = null,
    ...abc
  },
  header = headers()
) => {
  let request = {
    requestDate: requestDate,
    plantNo: plantNo,
    requestId: requestId,
    customerName: customerName,
    CustomerContact: CustomerContact,
    fromAddress: fromAddress,
    recipientContact: recipientContact,
    recipientName: recipientName,
    toAddress: toAddress,
    itemDetails: itemDetails,
    quantity: quantity,
    ...abc,
  };
  return api.post(url, request, {
    headers: header,
  });
};

/* ------------ Serial Number check ------------ */
export const ValidSerialNumberCheck = async (
  url,
  { serialNumber = null, outletId = null, userId = null, returnStock = false },
  header = headers()
) => {
  let request = {
    serialNumber: serialNumber,
    outletId: outletId,
    userId: userId,
    returnStock: returnStock,
  };
  return api.post(url, request, {
    headers: header,
  });
};

/* ------------ Switch User ------------ */
export const SwitchUser = async (url, username = null, header = headers()) => {
  return api.post(
    url,
    { username: username },
    {
      headers: header,
    }
  );
};

export const SwitchSupervisorUser = async (
  url,
  { username = null, outletId = null, requestedUser = null },
  header = headers()
) => {
  return api.post(
    url,
    { username: username, outletId: outletId, requestedUser: requestedUser },
    {
      headers: header,
    }
  );
};

/* ------------ Lovs Call ------------ */
export const GenericLovCalls = async (
  url,
  { searchBy = null },
  header = headers()
) => {
  return api.get(url, { params: { key: searchBy }, headers: header });
};
export const GenericLovCall = async (url, header = headers()) => {
  return api.get(url, header);
};

export const DirectLovCalls = async (url, header = headers()) => {
  return api.get(url, { headers: header });
};

export const GetDistrictByInstallerList = async (
  url,
  { filterList = false, supervisorId = null, outletId = null, district = "" },
  header = headers()
) => {
  let getInstallerbyDistrict = {
    filterList: filterList,
    supervisorId: supervisorId,
    outletId: outletId,
    district: district,
  };
  return api.post(url, getInstallerbyDistrict, { headers: header });
};
export const AssignInstallerLovCalls = async (
  url,
  { filterList = false, supervisorId = null, outletId = null },
  header = headers()
) => {
  let assignInstallerRequest = {
    filterList: filterList,
    supervisorId: supervisorId,
    outletId: outletId,
  };
  return api.post(url, assignInstallerRequest, { headers: header });
};

export const PostTest = async (url, header = {}) => {
  return api.get(url, header?.Authorization ? header : headers());
};

/* ------------ Validate Swapped devices are same model ------------ */
/* ----------CR-4012 Changes---------*/
export const ValidateDeviceSwap = async (
  url,
  { prevuin = null, uin = null },
  header = headers()
) => {
  let request = {
    prevuin: prevuin,
    uin: uin,
  };
  return api.post(url, request, {
    headers: header,
  });
};

/* ------------ Work Order Details Uploaded Documents:  ------------ */
export const InstallerUploadedDocuments = async (
  url,
  { orderId = null },
  header = headers()
) => {
  const finalUrl = url + orderId;
  return api.get(finalUrl, {
    headers: header,
  });
};

export const InstallerDownloadDocuments = async (
  url,
  { orderId = null, filename = null },
  header = headers()
) => {
  const finalUrl = url + orderId + "/" + filename;
  return api.get(finalUrl, {
    headers: header,
    responseType: "blob",
  });
};
/* ------------ Super Admin Uploaded Documents:  ------------ */
export const SuperAdminUploadedDocuments = async (
  url,
  request,
  header = headers()
) => {
  return api.post(url, request , {
    headers: header,
  });
};

/*------------ Super Admin View Notes -------------*/
export const SuperAdminViewNotes = async (
  url,
  request,
  header = headers()
) => {
  return api.post(url, request , {
    headers: header,
  });
};

/*------Signature document-------*/
export const SignatureDocument = async (
  url,
  request,
  header = headers()
) => {
  return api.post(url, request , {
    headers: header,
  });
};

