import React from "react";
import { Constent } from "../../../../../Assets/Constants/Constent";
import ReactSignatureCanvas from "react-signature-canvas";

const SignatureModal = ({ sign,
    setSign,
    installerUrl,
    setInstallerUrl,
    ...props }) => {

    const handleClear = () => {
        sign.clear()
        setInstallerUrl('')
       
    }
    const handleGenerate = () => {
        setInstallerUrl(sign.getTrimmedCanvas().toDataURL('image/png'))
    }

    return (
        <div
            className="modal fade sign-document-modal"
            id="instSignDocumentModal"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex="-1"
            aria-labelledby="signDocumentModalLabel"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header pb-0">
                        <div className="wrapper-modal-header pt-4">
                            <div className="modal-header-sign-section">
                                
                                <ReactSignatureCanvas penColor='green'
                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                    ref={data => setSign(data)} 
                                    />
                            </div>
                        </div>
                        </div>
                        <div className="modal-footer">
                            <div className="sign-modal-footer-section">
                                <div className="refresh-btn-section" data-dismiss="modal"
                                >
                                    {Constent.CANCEL_BTN}
                                </div>
                                <div className="refresh-btn-section" onClick={handleClear}>Clear</div>
                                <div className="refresh-btn-section" data-dismiss="modal" onClick={handleGenerate}>Save</div>
                            </div>
                        </div>
                    
                    
                </div>
            </div>
        </div>
    );
};

export default SignatureModal;
