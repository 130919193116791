const INSTALLER_PORAL_DOMAIN_NAME = "https://cfm.preprod.celcom.com.my/";
/* ----------- Lovs keys ------------ */
const LOV_DISTRICT_KEY = "INSDIS";
const LOV_INSTALLER_ID_TYPE_KEY = "IDTYPE";
const LOV_INVENTORY_REQUEST_STATUS_KEY = "INVRS";

const LOV_STORE_DISTRICT_KEY = "district";
const LOV_STORE_STATE_DISTRICT = "state";
const LOV_STORE_INSTALLER_ID_TYPE_KEY = "idtype";
const LOV_STORE_STATE_DIST_INSTALLER_KEY = "assigninstaller";
const LOV_STORE_CATEGORY_AND_BRAND_KEY = "invcategorybrand";
const LOV_STORE_ACCESS_PROVIDERS_KEY = "accessProviderLOV";

const LOV_STORE_REQUEST_STATUS_KEY = "invrequeststatus";
const LOV_STORE_TRACK_ORDER_CONTEXT_KEY = "ordercontext";
const LOV_STORE_TRACK_ORDER_STATUS_KEY = "orderstatus";
const LOV_STORE_TRACK_ORDER_SEARCH_TYPE_KEY = "ordersearchtype";
const LOV_STORE_UPDATE_WORK_ORDER_DISIRED_INSTALLATION_KEY =
  "desiredinstallation";
const LOV_STORE_UPDATE_WORK_ORDER_INFRASTRUCTURE_KEY = "infrastructure";
const LOV_STORE_UPDATE_WORK_ORDER_RETURN_REASON_KEY = "reaturnreason";
const LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_KEY = "reaturnremarks";
const LOV_STORE_UPDATE_WORK_ORDER_OST_ELEMENT_LOV_KEY = "elements";
const LOV_STORE_UPDATE_WORK_ORDER_OST_DEVICE_ELEMENT_LOV_KEY = "deviceelements";
const LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_KEY =
  "LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_KEY";

const WOKR_ORDER_OSI_TYPE = "Site_Installation";
const WOKR_ORDER_BTU_TYPE = "BTU_Collection";
const WOKR_ORDER_OST_TYPE = "Site_Troubleshooting";

const WOKR_ORDER_OSI_SUBTYPE_MAP1 = "New_Provide";
const WOKR_ORDER_OSI_SUBTYPE_MAP2 = "Return_Site_";
const WOKR_ORDER_OST_TYPE_MAP = "Troubleshooting";
const WOKR_ORDER_OST_SUBTYPE_MAP1 = "On_Site";

const WOKR_ORDER_RELOCATION_SUBTYPE_MAP = "Relocation";
const WOKR_ORDER_BTU_SUBTYPE_MAP1 = "Voluntary";
const WOKR_ORDER_BTU_SUBTYPE_MAP2 = "Involuntary";

const WOKR_ORDER_BTU_SUBTYPE_DISPLAY1 = "Voluntary Termination";
const WOKR_ORDER_BTU_SUBTYPE_DISPLAY2 = "Involuntary Termination";

const WOKR_ORDER_OSI_TYPE_DISPLAY = "On-Site Installation";
const WOKR_ORDER_OST_TYPE_DISPLAY = "On-Site Troubleshooting";

const WOKR_ORDER_OSI_SUBTYPE_DISPLAY1 = "New Installation";
const WOKR_ORDER_OST_SUBTYPE_DISPLAY1 = "On-Site Troubleshooting";

const WORK_ORDER_OSI_RELOCATION_TYPE = "Relocation";

const WOKR_ORDER_RETURNED = "RETURNED";
const WOKR_ORDER_INST_COMPLETE = "INST_COMPLETE";
const WOKR_ORDER_WO_COMPLETE = "WO_COMPLETE";

const WOKR_ORDER_OSI_SORT_TYPE = "OSI";
const WOKR_ORDER_BTU_SORT_TYPE = "BTU";
const WOKR_ORDER_OST_SORT_TYPE = "OST";

const WORK_ORDER_STATUS_ASSIGNED = "Assigned";
const WORK_ORDER_STATUS_SELF_ASSIGNED = "SelfAssigned";
const WORK_ORDER_STATUS_INSTALLER_ARRIVED = "Installer_Arrived";
const WORK_ORDER_STATUS_INSTALLATION_COMPLETED = "Installed";
const WORK_ORDER_STATUS_CLOSED = "Closed";
const WORK_ORDER_STATUS_CANCLED = "Cancel";
const WORK_ORDER_STATUS_RETURNED = "Returned";
const WORK_ORDER_STATUS_OPEN = "Open";

const WORK_ORDER_TASK_ID_READY = "READY";

const WORK_ORDER_DISPLAY_STATUS_PROGRESS = "In Progress";
const WORK_ORDER_DISPLAY_STATUS_NOT_STARTED = "Not Started";
const WORK_ORDER_ADVANCE_SEARCH_APPOINTMENT_TYPE_LOV = [
  {
    display: "All Appointments",
    value: "All_Appointments",
  },
];
const WORK_ORDER_ADVANCE_SEARCH_LOV1 = [
  {
    display: "On Site Installation",
    value: "Site_Installation",
    sub_Item: [
      {
        display: "New Provide",
        value: "New_Provide",
      },
      {
        display: "Return",
        value: "Return",
      },
      {
        display: "Relocation",
        value: "Relocation",
      },
      {
        display: "Return Relocation",
        value: "Return_Relocation",
      },
    ],
  },
  {
    display: "BTU Collection",
    value: "BTU_Collection",
    sub_Item: [
      {
        display: "Perform Voluntary",
        value: "Perform_Voluntary",
      },
      {
        display: "Perform Involuntary",
        value: "Perform_Involuntary",
      },
      {
        display: "Perform Relocation",
        value: "Perform_Relocation",
      },

      {
        display: "Return Voluntary",
        value: "Return_Voluntary",
      },
      {
        display: "Return Involuntary",
        value: "Return_Involuntary",
      },
      {
        display: "Return Relocation",
        value: "Return_Relocation",
      },
    ],
  },
  {
    display: "Site Troubleshooting",
    value: "Site_Troubleshooting",
    sub_Item: [
      {
        display: "On Site",
        value: "On",
      },
      {
        display: "Return On Site",
        value: "Return_On",
      },
    ],
  },
];

const WORK_ORDER_ADVANCE_SEARCH_LOV2 = [
  {
    display: "New Provide",
    value: "New_Provide",
  },
  {
    display: "Return",
    value: "Return",
  },
  {
    display: "Relocation",
    value: "Relocation",
  },
];
const TRACK_ORDER_SEARCH_TYPE_LOVS = [
  {
    display: "Order Number",
    value: "ORDERID",
  },
  {
    display: "Customer ID",
    value: "CUSTOMERID",
  },
  {
    display: "Service ID",
    value: "SERVICEID",
  },
  {
    display: "Order Status",
    value: "ORDERSTATUS",
  },
];
const TRACK_ORDER_CONTEXT_LOVS = [
  {
    display: "Device Swap",
    value: "Modify|Swap Device",
  },
  {
    display: "Add/Modify VAS",
    value: "Modify|Add/Remove/Modify VAS",
  },
];
const TRACK_ORDER_STATUS_LOVS = [
  {
    display: "In Progress",
    value: "IN_PROGRESS",
  },
  {
    display: "Completed",
    value: "COMPLETED",
  },
];

const CART_RETURN_REASON_LOVS = [
  { value: "Faulty", display: "Faulty" },
  { value: "Incomplete Set", display: "Incomplete Set" },
  { value: "Incorrect IMEI", display: "Incorrect IMEI" },
  { value: "Others", display: "Others" },
];
const CART_RETURN_REMARKS_LOVS = [
  { value: "DOA", display: "DOA" },
  { value: "Device Faulty RMA", display: "Device Faulty RMA" },
  { value: "Stock Obsolete", display: "Stock Obsolete" },
];

const WORK_ORDER_UPDATE_BTU_TYPE_LOVS = [
  { display: "Yes", value: "Yes" },
  { display: "No", value: "No" },
];

const UPLOAD_DOCUMENT_TYPE_LOVS = [
  { value: "Installation Form", display: "Installation Form" },
  { value: "Miscellaneous", display: "Miscellaneous" },
];

const CART_STORE_TO_TRANSFER_LOVS = [
  { value: "STH56541", display: "STH56541" },
  { value: "NTH12454", display: "NTH12454" },
  { value: "SBH53047", display: "SBH53047" },
  { value: "CSL56540", display: "CSL56540" },
  { value: "SWK54887", display: "SWK54887" },
  { value: "CSL55686", display: "CSL55686" },
  { value: "94006", display: "94006" },
  { value: "94005", display: "94005" },
  { value: "CKL64608", display: "CKL64608" },
  { value: "CSL64769", display: "CSL64769" },
  { value: "CKL64768", display: "CKL64768" },
  { value: "CSL65283", display: "CSL65283" },
  { value: "CSL65279", display: "CSL65279" },
  { value: "CSL65284", display: "CSL65284" },
  { value: "NTH65286", display: "NTH65286" },
  { value: "CSL65289", display: "CSL65289" },
  { value: "CSL65233", display: "CSL65233" },
  { value: "CSL65228", display: "CSL65228" },
  { value: "CSL65237", display: "CSL65237" },
  { value: "CSL61131", display: "CSL61131" },
];

/* ----------- Work Order ------------ */
const WORK_ORDER_ID = "WORK_ORDER_ID";
const WORK_ORDER_FROM_DATE = "WORK_ORDER_FROM_DATE";
const WORK_ORDER_TO_DATE = "WORK_ORDER_TO_DATE";
const WORK_ORDER_RESET_FIELD = "WORK_ORDER_RESET_FIELD";

const INSTALLER_WORK_ORDER_IN_PROGRESS_STATUS = [
  "Installer_Arrived",
  "Installed",
];

const INSTALLER_WORK_ORDER_CLOSED_STATUS = ["Closed"];
const INSTALLER_WORK_ORDER_OPEN_STATUS = ["Assigned"];

const CALENDAR_TO_WORK_ORDER_NAV = "CALENDAR_TO_WORK_ORDER_NAV";
const INSTALLER_WORK_ORDER_ACTIVE_TAB = "INSTALLER_WORK_ORDER_ACTIVE_TAB";
const INSTALLER_WORK_ORDER_UPDATE_ACTIVE = "INSTALLER_WORK_ORDER_UPDATE_ACTIVE";

const WORK_ORDER_TASK_ID = "READY";

const OST_DEVICE_ACTIVE = "Active";
const OST_DEVICE_INACTIVE = "IA";

const WORK_ORDER_DOCUMENT_LIST = "WORK_ORDER_DOCUMENT_LIST";
const WORK_ORDER_UPLOADED_DOCUMENT_LIST = "WORK_ORDER_UPLOADED_DOCUMENT_LIST";
const INSTALLER_SL_VALIDATION = "INSTALLER_SL_VALIDATION";
const INSTALLER_CLOSE_WORK_ORDER_TYPE = "INSTALLER_CLOSE_WORK_ORDER_TYPE";
const INSTALLER_SL_EXISTING_SERIAL_NUMBER =
  "INSTALLER_SL_EXISTING_SERIAL_NUMBER";
const INSTALLER_SL_VALIDATION_SERIAL_NUMBER =
  "INSTALLER_SL_VALIDATION_SERIAL_NUMBER";
const INSTALLER_SL_VALIDATION_VALID = "INSTALLER_SL_VALIDATION_VALID";
const INSTALLER_SL_INNER_DROP_DOWN_ELEMENT_VALUE =
  "INSTALLER_SL_INNER_DROP_DOWN_ELEMENT_VALUE";
const INSTALLER_SL_INNER_DROP_DOWN_REASON_VALUE =
  "INSTALLER_SL_INNER_DROP_DOWN_REASON_VALUE";
const INSTALLER_SL_INNER_DROP_DOWN_PROBLEM_CAUSE_VALUE =
  "INSTALLER_SL_INNER_DROP_DOWN_PROBLEM_CAUSE_VALUE";

const INSTALLER_SL_VALIDATION_DEVICE_SWAP_MODEL_DETAILS =
  "INSTALLER_SL_VALIDATION_DEVICE_SWAP_MODEL_DETAILS";

const INSTALLER_WORK_ORDER_MODEL_CHANGE_IN_SWAP_DEVICES =
  "INSTALLER_WORK_ORDER_MODEL_CHANGE_IN_SWAP_DEVICES";

const INSTALLER_BTU_ID_INPUTVALUE = "INSTALLER_BTU_ID_INPUTVALUE";
const INSTALLER_BTU_PORT_INPUTVALUE = "INSTALLER_BTU_PORT_INPUTVALUE";
const INSTALLER_BTU_DEVICE_COLLECTED_INPUTVALUE =
  "INSTALLER_BTU_DEVICE_COLLECTED_INPUTVALUE";

const INSTALLER_ADDITIONAL_CABLE_LENGTH_INPUTVALUE =
  "INSTALLER_ADDITIONAL_CABLE_LENGTH_INPUTVALUE";
const INSTALLER_WORK_ORDER_STATUS = "INSTALLER_WORK_ORDER_STATUS";

const INSTALLER_WORK_ORDER_OST_CHECKED_DEVICE_TYPE =
  "INSTALLER_WORK_ORDER_OST_CHECKED_DEVICE_TYPE";

const INSTALLER_WORK_ORDER_OST_ELEMENT_LOV_VALUE =
  "INSTALLER_WORK_ORDER_OST_ELEMENT_LOV_VALUE";
const INSTALLER_WORK_ORDER_OST_RESOLUTION_LOV_VALUE =
  "INSTALLER_WORK_ORDER_OST_RESOLUTION_LOV_VALUE";
const INSTALLER_WORK_ORDER_OST_PROBLEM_CASE_LOV_VALUE =
  "INSTALLER_WORK_ORDER_OST_PROBLEM_CASE_LOV_VALUE";

const INSTALLER_WORK_ORDER_OST_ORDER_ID = "INSTALLER_WORK_ORDER_OST_ORDER_ID";

const INSTALLER_WORK_ORDER_CALENDAR_DATE = "INSTALLER_WORK_ORDER_CALENDAR_DATE";

const INSTALLER_ENABLED_LOCATION_BTN = "INSTALLER_ENABLED_LOCATION_BTN";
const INSTALLER_COMPLETE_TIME = "INSTALLER_COMPLETE_TIME";

const UPDATE_WORK_ORDER_STATUS_VALUE = "UPDATE_WORK_ORDER_STATUS_VALUE";
const UPDATE_WORK_ORDER_UPLOAD_DOCUMENT = "UPDATE_WORK_ORDER_UPLOAD_DOCUMENT";

const UPDATE_WORK_ORDER_SAVE_AND_CONTINUE_BTN_VALUE =
  "UPDATE_WORK_ORDER_SAVE_AND_CONTINUE_BTN_VALUE";
const UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE = "UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE";

const VALID_INP = "valid";
const INVALID_INP = "invalid";

const FILE_TYPE_PDF = "FILE_TYPE_PDF";
const FILE_TYPE_DOC = "FILE_TYPE_DOC";

const SAVE_SUMMARY_DATA = "SAVE_SUMMARY_DATA";
const SAVE_SUMMARY_DATE = "SAVE_SUMMARY_DATE";

const SUMMARY_PILL_ITEM = "SUMMARY_PILL_ITEM";
const SUMMARY_PILL_STATUS_ITEM = "SUMMARY_PILL_STATUS_ITEM";

const EMPTY_SUMMARY_DATA = {
  installation: {
    progress: [0, 0.0, []],
    done: [0, 0.0, []],
    returned: [0, 0.0, []],
    aging: [0, 0.0, []],
  },
  troubleshooting: {
    progress: [0, 0.0, []],
    done: [0, 0.0, []],
    returned: [0, 0.0, []],
    aging: [0, 0.0, []],
  },
  btu_collection: {
    progress: [0, 0.0, []],
    done: [0, 0.0, []],
    returned: [0, 0.0, []],
    aging: [0, 0.0, []],
  },
};

const UPLOAD_DOCUMENT_ITEM_NOT_COMPLETE_STATUS = "Not Done";
const UPLOAD_DOCUMENT_ITEM_PROGRESS_STATUS = "Progress";
const UPLOAD_DOCUMENT_ITEM_COMPLETE_STATUS = "Done";

/* ----------- Manage Installer Filter keys ------------ */
const SEARCH_INSTALLER_KEYS = ["firstName", "lastName", "mobileNo"];

const MANAGE_INSTALLER_INPUT = "MANAGE_INSTALLER_INPUT";
const CREATE_INSTALLER_RESPONSE = "CREATE_INSTALLER_RESPONSE";
/* ----------- Manage Inventory ------------ */
const INVENTORY_ITEM_TYPE_ID = "DEVICE";
const INVENTORY_REQUESTING_STORE_ID = "CSL60182";

const INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE1 = "";
const INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE2 = "IN STOCK";
const INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE3 = "UNAVAILABLE";
const INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE4 = "SOLD";
const INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE5 = "FAULTY";
const INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE6 = "OTHERS";

const INVENTORY_REDUX_STOCK_LIST_TYPE1 = "manageStockList";
const INVENTORY_REDUX_STOCK_LIST_TYPE2 = "requestStockList";
const INVENTORY_REDUX_STOCK_LIST_TYPE3 = "brandLov";
const INVENTORY_REDUX_STOCK_LIST_TYPE4 = "trackRequestStockList";
const INVENTORY_REDUX_STOCK_LIST_TYPE5 = "trackReturnStockList";

const INVENTORY_TRACK_STOCK_REQUEST_TYPE = "Request Stock";
const INVENTORY_TRANSFER_STOCK_REQUEST_TYPE = "Transfer Stock";
const INVENTORY_TRACK_STOCK_RETURN_TYPE = "Return Stock";

const INVENTORY_TRACK_STOCK_AWAITING_RESPONSE = "Awaiting Response";
const INVENTORY_TRACK_STOCK_REQUEST_ID_VALUE = "trackStockRequestIdValue";
const INVENTORY_TRACK_STOCK_FORM_DATE_VALUE = "trackStockFromDateValue";
const INVENTORY_TRACK_STOCK_TO_DATE_VALUE = "trackStockToDateValue";
const INVENTORY_TRACK_STOCK_REQUEST_STATUS_VALUE =
  "trackStockRequestStatusValue";

const INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM = "selectedTrackStockDataItem";
const INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS =
  "selectedTrackStockDataItemDetails";
const INVENTORY_TRACK_STOCK_RESET_SELECTED_DATA_ITEM =
  "resetSelectedTrackStockDataItem";

const INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS_APPROVE_QNT_UPDATE =
  "TRACK_STOCK_UPDATE_APPROVE_QUNT";

const INVENTORY_TRACK_STOCK_PROGRESS_STATUS = [
  "Receiving",
  "Inbound-Picking",
  "Awaiting Response",
  "In-Progress",
];
const INVENTORY_TRACK_STOCK_COMPLETED_STATUS = [
  "In-Transit",
  "Received",
  "Dispatched",
];
const INVENTORY_TRACK_STOCK_NOT_STARTED_STATUS = [
  "Rejected Request",
  "Cancelled Request",
  "Inbound-Picking",
  "Inbound-Cancelled",
];
const TRACK_STOCK_APPROVE_BTN_STATUS = ["Awaiting Response"];

const TRACK_STOCK_APPROVER_COMMENT = "TRACK_STOCK_APPROVER_COMMENT";
const ADMIN_TRACK_STOCK_CONFIRMATION_MSG = "ADMIN_TRACK_STOCK_CONFIRMATION_MSG";
const APPROVED_SUCESSFULL_MSG = "You have Approved a Stock Request.";
const REJECT_SUCESSFULL_MSG = "You have Rejected a Stock Request.";

const SUPERVISOR_TRACK_STOCK_CONFIRMATION_MSG =
  "SUPERVISOR_TRACK_STOCK_CONFIRMATION_MSG";

const ACKNOWLEDGE_EMPTY_CHECKBOX_SELECTION =
  "ACKNOWLEDGE_EMPTY_CHECKBOX_SELECTION";
const ACKNOWLEDGE_CHECKBOX_SELECTION = "ACKNOWLEDGE_CHECKBOX_SELECTION";
const ACKNOWLEDGE_CHECKBOX_UNSELECTION = "ACKNOWLEDGE_CHECKBOX_UNSELECTION";
const ACKNOWLEDGE_CHECKBOX_SELECT_ALL = "ACKNOWLEDGE_CHECKBOX_SELECT_ALL";

const ACKNOWLEDGE_TOTAL_ITEMS = "ACKNOWLEDGE_TOTAL_ITEMS";
const ACKNOWLEDGE_RECEIVED_ITEMS = "ACKNOWLEDGE_RECEIVED_ITEMS";

/* ----------- Track Order ------------ */
const TRACK_ORDER_SEARCH_TYPE_VALUE = "Awaiting Response";
const TRACK_ORDER_STATUS_VALUE = "ORDERSTATUS";
const TRACK_ORDER_FORM_DATE_VALUE = "trackOrderFromDateValue";
const TRACK_ORDER_TO_DATE_VALUE = "trackOrderToDateValue";
const TRACK_ORDER_CONTEXT_VALUE = "trackOrderContextValue";
const TRACK_ORDER_CUSTOMER_ID_VALUE = "CUSTOMERID";
const TRACK_ORDER_ORDER_ID_VALUE = "ORDERID";
const TRACK_ORDER_SERVICE_KEY_VALUE = "trackOrderServiceKeyValue";
const TRACK_ORDER_SERVICE_VAL_VALUE = "SERVICEID";
const TRACK_ORDER_RESET_VALUE = "TRACK_ORDER_RESET";

const TRACK_ORDER_LIST_DATA = "TRACK_ORDER_LIST_DATA";
const TRACK_ORDER_SELECTED_DATA = "TRACK_ORDER_SELECTED_DATA";

const TRACK_ORDER_RESET_LIST_DATA = "TRACK_ORDER_RESET_LIST_DATA";
const TRACK_ORDER_RESET_SELECTED_DATA = "TRACK_ORDER_RESET_SELECTED_DATA";

const TRACK_ORDER_IN_PROGRESS_STATUS = "IN_PROGRESS";
const TRACK_ORDER_COMPLETE_STATUS = "COMPLETED";

const TRACK_ORDER_IN_PROGRESS_STATUS_DISPLAY = "In Progress";
const TRACK_ORDER_COMPLETE_STATUS_DISPLAY = "Completed";

/* ----------- Cart ------------ */
const CART_REDUX_RETURN_REASON_VALUE = "returnReasonValue";
const CART_REDUX_RETURN_REMARK_VALUE = "returnRemarkValue";
const CART_REDUX_TRANSFER_REMARK_VALUE = "storeTransferValue";

const CART_REDUX_RETURN_SERIAL_NUMBER_ADD_VALUE = "addSerialNumbers";
const CART_REDUX_RETURN_SERIAL_NUMBER_REMOVE_VALUE = "removeSerialNumbers";
const CART_REDUX_RETURN_SERIAL_NUMBER_REMOVE_ALL_VALUE =
  "removeAllSerialNumbers";

const RETURN_STOCK_CART_REQUEST_ACTION = "RETURN";
const RETURN_STOCK_CART_REQUEST_SOURCE_SYSTEM = "CFMP";

const RETURN_STOCK_CART_REQUEST_BLANK_VALUE = "-";
const ADD_STOCK_QUNT_UPDATE = "ADD_STOCK_QUNT_UPDATE";
const SELECTED_STOCK_QUNT_UPDATE = "SELECTED_ADD_STOCK_QUNT_UPDATE";

const REMOVE_CART_CONFIRMATION_MSG = "REMOVE_CART_CONFIRMATION_MSG";

/* ------------- Warning Messages ---------------- */
const WORK_ORDER_LIST_WARNING_MESSAGE =
  "Sorry! No Data Found, Please try with different input or try again later";
const INVENTORY_VALID_INPUT_WARING_MESSAGE = "Please enter valid input";
const TRACK_ORDER_WARNING_MESSAGE =
  "Sorry, no unable to fetch order data, Please try again later";
const WORK_ORDER_REASSIGN_INSTALLER_WORKING_MESSAGE =
  "Unable to Assign Installer, Please try with different Installer";
const ACKNOWLEDGE_VALIDATION_WARNING_MSG =
  "Sorry! Unable to Acknowledge, as Request Quantity and Acknowledge Quantity not matched. Please Select All";

const INSTALLER_CALENDAR_WARNING_MESSAGE =
  "Sorry! today there is no Work Order for you, please try different date";
const INSTALLER_SIDEBAR_WARNING_MESSAGE =
  "Sorry! you don't have any task in this month, please check in different month";
const INSTALLER_ACTIVITY_DETAILS_NO_DEVICE_DETAILS_WARNING_MESSAGE =
  "Sorry! No Device Details available to update, please try a different work order";
const APPROVE_QUANTITY_NOT_MATCH_WARNING_MESSAGE =
  "Request Quantity and Approved Quantity Not Matching, Please add Quantity";
const DOWNLOAD_DOCUMENT_WARNING_MESSAGE =
  "Sorry! Unable to download this document, please try again";
const FILE_SIZE_EXIDED_WARNING_MESSAGE =
  "Sorry, unable to attach the file. It's exceeded the upload limit 50MB";
const UPLOAD_FILE_VALIDATION_WARNING_MESSAGE =
  "Please attach some documents or Select the document type";
const UNSUPPORTED_FILE_WARNING_MESSAGE = "Sorry unsupported file extention";
const INSTALLER_INST_SWAP_DEVICE_MODEL_CHANGE_WARNING_MESSAGE =
  "Model is Changing. Additional Charges may apply";
const FILE_NAME_DUPLICATE =
  "Sorry! This file has been uploaded, please select a different file";

/* ------------- Error Messages ---------------- */
const GET_INSTALLER_ERROR_MESSAGE =
  "Sorry! Unable to fetch Installer data from server, Please try again later";
const GET_INSTALLER_ERROR_MESSAGE_NO_DATA =
  "Sorry! No data Found, Please try again later";
const GET_INSTALLER_ERROR_MESSAGE_WRONG =
  "Something went wrong. Please try again.";
const WORK_ORDER_LIST_ERROR_MESSAGE =
  "Sorry! Unable to fetch Work Order data from server, Please try again later";
const VIEW_STOCK_LIST_ERROR_MESSAGE =
  "Sorry! Unable to fetch Store data from server, Please try again later";
const TRACK_ORDER_LIST_ERROR_MESSAGE =
  "Sorry! Unable to fetch Order data from server, Please try again later";
const RETURN_LOV_ERROR_MESSAGE =
  "Please select the valid return reason and remaks";
const VALID_SERIAL_NUMBER_ERROR_MESSAGE =
  "Sorry! Unable to validates IMEI from server, Please try again later";
const SUBMIT_RETURN_SERIAL_NUMBERS_ERROR_MESSAGE =
  "Sorry! Unable to return the stocks, Please try again later";
const SUBMIT_REQUEST_STOCK_ERROR_MESSAGE =
  "Sorry! Unable to request the stocks, Please try again later";
const SUBMIT_APPROVE_ERROR_MESSAGE =
  "Sorry! Unable to approve the stocks, Please try again later";
const SUBMIT_TRANSFER_STOCK_ERROR_MESSAGE =
  "Sorry! Unable to transfer the stocks, Please try again later";

const ACKNOWLEDGE_ERROR_MSG =
  "Sorry! Unable to Acknowledge the request, Please try again later";

const INSTALLER_CALENDAR_ERROR_MESSAGE =
  "Sorry! Unable to fetch data from server for Calendar, please try again later";
const INSTALLER_SUMMARY_ERROR_MESSAGE =
  "Sorry! Unable to fetch data from server for Summary, please try again later";

const INSTALLER_ENABLE_LOCATION_ERROR_MESSAGE =
  "Please allow access of Location, PERMISSION DENIED";
const INSTALLER_LOCATION_ERROR_MESSAGE =
  "Sorry! server is unable to capture the Location details, please try again later";
const INSTALLER_INST_COMPLETE_ERROR_MESSAGE =
  "Sorry! Unable to update the work order status, please try again later";

const OST_DEVICE_SELECTION_WARNING_MESSAGE =
  "Sorry! You can't select two different types for Devices";

const SUMMARY_ERROR_MESSAGE =
  "Sorry! Unable to fetch data or no data available in this month, please try again later";

export const ApiConstant = {
  INSTALLER_PORAL_DOMAIN_NAME,
  OST_DEVICE_ACTIVE,
  OST_DEVICE_INACTIVE,
  INVENTORY_REQUESTING_STORE_ID,
  INSTALLER_ENABLE_LOCATION_ERROR_MESSAGE,
  INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE1,
  ACKNOWLEDGE_TOTAL_ITEMS,
  UPLOAD_DOCUMENT_TYPE_LOVS,
  INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE2,
  INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE3,
  ACKNOWLEDGE_RECEIVED_ITEMS,
  CART_REDUX_TRANSFER_REMARK_VALUE,
  UPLOAD_DOCUMENT_ITEM_NOT_COMPLETE_STATUS,
  UPLOAD_DOCUMENT_ITEM_COMPLETE_STATUS,
  UPLOAD_DOCUMENT_ITEM_PROGRESS_STATUS,
  INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE4,
  WORK_ORDER_ID,
  APPROVE_QUANTITY_NOT_MATCH_WARNING_MESSAGE,
  WORK_ORDER_FROM_DATE,
  FILE_SIZE_EXIDED_WARNING_MESSAGE,
  UNSUPPORTED_FILE_WARNING_MESSAGE,
  FILE_NAME_DUPLICATE,
  INSTALLER_INST_SWAP_DEVICE_MODEL_CHANGE_WARNING_MESSAGE,
  WORK_ORDER_TO_DATE,
  UPLOAD_FILE_VALIDATION_WARNING_MESSAGE,
  INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE5,
  INVENTORY_CARD_VIEW_STOCK_ITEM_VALUE6,
  OST_DEVICE_SELECTION_WARNING_MESSAGE,
  ACKNOWLEDGE_VALIDATION_WARNING_MSG,
  WORK_ORDER_REASSIGN_INSTALLER_WORKING_MESSAGE,
  TRACK_ORDER_WARNING_MESSAGE,
  ACKNOWLEDGE_CHECKBOX_SELECTION,
  CART_STORE_TO_TRANSFER_LOVS,
  LOV_DISTRICT_KEY,
  LOV_STORE_UPDATE_WORK_ORDER_OST_ELEMENT_LOV_KEY,
  LOV_INSTALLER_ID_TYPE_KEY,
  LOV_STORE_UPDATE_WORK_ORDER_OST_DEVICE_ELEMENT_LOV_KEY,
  LOV_STORE_CATEGORY_AND_BRAND_KEY,
  LOV_STORE_ACCESS_PROVIDERS_KEY,
  ACKNOWLEDGE_CHECKBOX_UNSELECTION,
  LOV_STORE_DISTRICT_KEY,
  LOV_STORE_INSTALLER_ID_TYPE_KEY,
  LOV_STORE_STATE_DISTRICT,
  LOV_STORE_STATE_DIST_INSTALLER_KEY,
  WORK_ORDER_ADVANCE_SEARCH_LOV1,
  WORK_ORDER_ADVANCE_SEARCH_LOV2,
  WORK_ORDER_ADVANCE_SEARCH_APPOINTMENT_TYPE_LOV,
  WORK_ORDER_RESET_FIELD,
  WORK_ORDER_DOCUMENT_LIST,
  WORK_ORDER_UPLOADED_DOCUMENT_LIST,
  WORK_ORDER_STATUS_ASSIGNED,
  WORK_ORDER_STATUS_SELF_ASSIGNED,
  WORK_ORDER_STATUS_OPEN,
  WOKR_ORDER_INST_COMPLETE,
  WORK_ORDER_STATUS_INSTALLER_ARRIVED,
  WORK_ORDER_STATUS_INSTALLATION_COMPLETED,
  ACKNOWLEDGE_CHECKBOX_SELECT_ALL,
  WORK_ORDER_STATUS_CLOSED,
  WORK_ORDER_STATUS_CANCLED,
  WORK_ORDER_STATUS_RETURNED,
  WORK_ORDER_TASK_ID_READY,
  ACKNOWLEDGE_ERROR_MSG,
  WOKR_ORDER_OSI_SUBTYPE_MAP1,
  WOKR_ORDER_OSI_SUBTYPE_MAP2,
  WORK_ORDER_DISPLAY_STATUS_PROGRESS,
  WORK_ORDER_DISPLAY_STATUS_NOT_STARTED,
  REMOVE_CART_CONFIRMATION_MSG,
  WOKR_ORDER_OST_TYPE_MAP,
  WOKR_ORDER_OST_TYPE,
  WORK_ORDER_OSI_RELOCATION_TYPE,
  INVENTORY_ITEM_TYPE_ID,
  WOKR_ORDER_OSI_TYPE_DISPLAY,
  TRACK_ORDER_LIST_DATA,
  WOKR_ORDER_OST_TYPE_DISPLAY,
  TRACK_ORDER_SELECTED_DATA,
  WOKR_ORDER_OST_SUBTYPE_MAP1,
  SEARCH_INSTALLER_KEYS,
  WOKR_ORDER_OSI_SUBTYPE_DISPLAY1,
  LOV_STORE_REQUEST_STATUS_KEY,
  WOKR_ORDER_OST_SUBTYPE_DISPLAY1,
  LOV_STORE_TRACK_ORDER_CONTEXT_KEY,
  WOKR_ORDER_RELOCATION_SUBTYPE_MAP,
  SUBMIT_REQUEST_STOCK_ERROR_MESSAGE,
  SUBMIT_TRANSFER_STOCK_ERROR_MESSAGE,
  WOKR_ORDER_BTU_SUBTYPE_MAP1,
  LOV_STORE_TRACK_ORDER_STATUS_KEY,
  WOKR_ORDER_BTU_SUBTYPE_MAP2,
  TRACK_ORDER_SEARCH_TYPE_LOVS,
  WOKR_ORDER_BTU_SUBTYPE_DISPLAY1,
  TRACK_ORDER_SEARCH_TYPE_VALUE,
  WOKR_ORDER_BTU_SUBTYPE_DISPLAY2,
  TRACK_ORDER_STATUS_VALUE,
  TRACK_ORDER_FORM_DATE_VALUE,
  TRACK_ORDER_TO_DATE_VALUE,
  WOKR_ORDER_RETURNED,
  INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS_APPROVE_QNT_UPDATE,
  TRACK_STOCK_APPROVE_BTN_STATUS,
  TRACK_ORDER_CONTEXT_VALUE,
  TRACK_ORDER_CUSTOMER_ID_VALUE,
  TRACK_ORDER_ORDER_ID_VALUE,
  TRACK_STOCK_APPROVER_COMMENT,
  TRACK_ORDER_SERVICE_KEY_VALUE,
  TRACK_ORDER_SERVICE_VAL_VALUE,
  TRACK_ORDER_RESET_LIST_DATA,
  TRACK_ORDER_CONTEXT_LOVS,
  TRACK_ORDER_STATUS_LOVS,
  LOV_STORE_TRACK_ORDER_SEARCH_TYPE_KEY,
  RETURN_STOCK_CART_REQUEST_ACTION,
  RETURN_STOCK_CART_REQUEST_BLANK_VALUE,
  TRACK_ORDER_RESET_VALUE,
  RETURN_STOCK_CART_REQUEST_SOURCE_SYSTEM,
  INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM,
  TRACK_ORDER_IN_PROGRESS_STATUS,
  TRACK_ORDER_COMPLETE_STATUS,
  TRACK_ORDER_IN_PROGRESS_STATUS_DISPLAY,
  TRACK_ORDER_COMPLETE_STATUS_DISPLAY,
  INVENTORY_TRACK_STOCK_SELECTED_DATA_ITEM_DETAILS,
  LOV_INVENTORY_REQUEST_STATUS_KEY,
  TRACK_ORDER_RESET_SELECTED_DATA,
  INVENTORY_TRACK_STOCK_REQUEST_ID_VALUE,
  INVENTORY_TRACK_STOCK_FORM_DATE_VALUE,
  INVENTORY_TRACK_STOCK_TO_DATE_VALUE,
  INVENTORY_TRACK_STOCK_REQUEST_STATUS_VALUE,
  INVENTORY_TRACK_STOCK_REQUEST_TYPE,
  INVENTORY_TRANSFER_STOCK_REQUEST_TYPE,
  INVENTORY_TRACK_STOCK_RETURN_TYPE,
  INVENTORY_REDUX_STOCK_LIST_TYPE1,
  INVENTORY_REDUX_STOCK_LIST_TYPE2,
  INVENTORY_REDUX_STOCK_LIST_TYPE3,
  INVENTORY_REDUX_STOCK_LIST_TYPE4,
  INVENTORY_REDUX_STOCK_LIST_TYPE5,
  INVENTORY_TRACK_STOCK_AWAITING_RESPONSE,
  CART_REDUX_RETURN_REASON_VALUE,
  CART_REDUX_RETURN_REMARK_VALUE,
  CART_REDUX_RETURN_SERIAL_NUMBER_ADD_VALUE,
  CART_REDUX_RETURN_SERIAL_NUMBER_REMOVE_VALUE,
  CART_REDUX_RETURN_SERIAL_NUMBER_REMOVE_ALL_VALUE,
  WORK_ORDER_LIST_WARNING_MESSAGE,
  VIEW_STOCK_LIST_ERROR_MESSAGE,
  VALID_SERIAL_NUMBER_ERROR_MESSAGE,
  GET_INSTALLER_ERROR_MESSAGE,
  GET_INSTALLER_ERROR_MESSAGE_NO_DATA,
  GET_INSTALLER_ERROR_MESSAGE_WRONG,
  WORK_ORDER_LIST_ERROR_MESSAGE,
  INVENTORY_VALID_INPUT_WARING_MESSAGE,
  SUBMIT_RETURN_SERIAL_NUMBERS_ERROR_MESSAGE,
  RETURN_LOV_ERROR_MESSAGE,
  TRACK_ORDER_LIST_ERROR_MESSAGE,
  INVENTORY_TRACK_STOCK_PROGRESS_STATUS,
  INVENTORY_TRACK_STOCK_COMPLETED_STATUS,
  SELECTED_STOCK_QUNT_UPDATE,
  INVENTORY_TRACK_STOCK_NOT_STARTED_STATUS,
  INVENTORY_TRACK_STOCK_RESET_SELECTED_DATA_ITEM,
  ADMIN_TRACK_STOCK_CONFIRMATION_MSG,
  APPROVED_SUCESSFULL_MSG,
  REJECT_SUCESSFULL_MSG,
  SUBMIT_APPROVE_ERROR_MESSAGE,
  ADD_STOCK_QUNT_UPDATE,
  ACKNOWLEDGE_EMPTY_CHECKBOX_SELECTION,
  SUPERVISOR_TRACK_STOCK_CONFIRMATION_MSG,
  INSTALLER_BTU_PORT_INPUTVALUE,
  INSTALLER_BTU_DEVICE_COLLECTED_INPUTVALUE,
  INSTALLER_CALENDAR_ERROR_MESSAGE,
  INSTALLER_CALENDAR_WARNING_MESSAGE,
  INSTALLER_SIDEBAR_WARNING_MESSAGE,
  INSTALLER_SUMMARY_ERROR_MESSAGE,
  INSTALLER_WORK_ORDER_IN_PROGRESS_STATUS,
  INSTALLER_WORK_ORDER_CLOSED_STATUS,
  INSTALLER_SL_VALIDATION,
  INSTALLER_CLOSE_WORK_ORDER_TYPE,
  INSTALLER_WORK_ORDER_OPEN_STATUS,
  WORK_ORDER_TASK_ID,
  INSTALLER_ENABLED_LOCATION_BTN,
  WOKR_ORDER_WO_COMPLETE,
  INSTALLER_SL_EXISTING_SERIAL_NUMBER,
  UPDATE_WORK_ORDER_STATUS_VALUE,
  UPDATE_WORK_ORDER_UPLOAD_DOCUMENT,
  INSTALLER_BTU_ID_INPUTVALUE,
  WOKR_ORDER_OSI_TYPE,
  WOKR_ORDER_BTU_TYPE,
  WOKR_ORDER_OSI_SORT_TYPE,
  INSTALLER_COMPLETE_TIME,
  WOKR_ORDER_BTU_SORT_TYPE,
  WOKR_ORDER_OST_SORT_TYPE,
  CALENDAR_TO_WORK_ORDER_NAV,
  INSTALLER_WORK_ORDER_ACTIVE_TAB,
  SUMMARY_ERROR_MESSAGE,
  INSTALLER_ACTIVITY_DETAILS_NO_DEVICE_DETAILS_WARNING_MESSAGE,
  INSTALLER_WORK_ORDER_UPDATE_ACTIVE,
  INSTALLER_SL_VALIDATION_SERIAL_NUMBER,
  INSTALLER_SL_VALIDATION_VALID,
  INSTALLER_SL_VALIDATION_DEVICE_SWAP_MODEL_DETAILS,
  INSTALLER_WORK_ORDER_MODEL_CHANGE_IN_SWAP_DEVICES,
  INSTALLER_SL_INNER_DROP_DOWN_REASON_VALUE,
  VALID_INP,
  INSTALLER_SL_INNER_DROP_DOWN_ELEMENT_VALUE,
  INSTALLER_WORK_ORDER_OST_CHECKED_DEVICE_TYPE,
  INVALID_INP,
  INSTALLER_WORK_ORDER_OST_ELEMENT_LOV_VALUE,
  LOV_STORE_UPDATE_WORK_ORDER_DISIRED_INSTALLATION_KEY,
  LOV_STORE_UPDATE_WORK_ORDER_INFRASTRUCTURE_KEY,
  INSTALLER_ADDITIONAL_CABLE_LENGTH_INPUTVALUE,
  INSTALLER_WORK_ORDER_OST_ORDER_ID,
  INSTALLER_WORK_ORDER_STATUS,
  INSTALLER_WORK_ORDER_OST_PROBLEM_CASE_LOV_VALUE,
  INSTALLER_LOCATION_ERROR_MESSAGE,
  INSTALLER_WORK_ORDER_OST_RESOLUTION_LOV_VALUE,
  LOV_STORE_UPDATE_WORK_ORDER_RETURN_REASON_KEY,
  LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_KEY,
  LOV_STORE_UPDATE_WORK_ORDER_RETURN_REMARKS_INPUT_KEY,
  INSTALLER_INST_COMPLETE_ERROR_MESSAGE,
  UPDATE_WORK_ORDER_SAVE_AND_CONTINUE_BTN_VALUE,
  UPDATE_WORK_ORDER_SUBMIT_BTN_VALUE,
  DOWNLOAD_DOCUMENT_WARNING_MESSAGE,
  FILE_TYPE_DOC,
  INSTALLER_SL_INNER_DROP_DOWN_PROBLEM_CAUSE_VALUE,
  FILE_TYPE_PDF,
  SAVE_SUMMARY_DATA,
  EMPTY_SUMMARY_DATA,
  SAVE_SUMMARY_DATE,
  CREATE_INSTALLER_RESPONSE,
  SUMMARY_PILL_STATUS_ITEM,
  SUMMARY_PILL_ITEM,
  INSTALLER_WORK_ORDER_CALENDAR_DATE,
  MANAGE_INSTALLER_INPUT,
  CART_RETURN_REASON_LOVS,
  CART_RETURN_REMARKS_LOVS,
  WORK_ORDER_UPDATE_BTU_TYPE_LOVS,
};
