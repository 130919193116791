import moment from 'moment';
import React from 'react'
import DatePicker from 'react-date-picker';
import { Constent } from '../../../../Assets/Constants/Constent';
import { CalendarIcon, LeftArrowIcon, RightArrowIcon, ToggleBtnIcon1, ToggleBtnIcon2 } from '../../../../Assets/Images/CelcomSvgImages';
import { SentenceCaseConverter } from '../../../Common/GenericFunctions';

const DashbordBodyHeader = ({ userDataObj, globalState, pageState, setPageState, index }) => {
    const userChanged = (userDataObj?.userData?.activeUserData?.userRole !== userDataObj?.userData?.loginUserData?.userRole)
        ||
        (userDataObj?.userData?.loginUserData?.userRole === Constent.SUPER_ROLE && 
            (userDataObj?.userData?.loginUserData?.userData?.userId !== userDataObj?.userData?.activeUserData?.userData?.userId));

    return (
        <div className="dashbord-header-start-section">
            <div className={userChanged ?
                "dashbord-header-banner-content-section admin-dashbord-header-banner-content-section"
                :
                "dashbord-header-banner-content-section"}
                data-aos="zoom-in" data-aos-easing="ease-in-out">
                <div className='old-bcdp-link'>Visit old <a href="http://sitc3-wcp.celcom.com.my:8001/servicecenter/faces/oracle/webcenter/portalapp/pages/dealer/dlogin.jspx">BCDP</a> portal.</div>
                <h3>{Constent.BODY_HEADER_DASHBORD_BANNAR_SUB_TEXT}
                    &nbsp;{SentenceCaseConverter({ string: userDataObj?.userData?.loginUserData?.userData?.firstName })}!
                </h3>
                {
                    userChanged ?
                        <h5>{`${Constent.BODY_HEADER_ADMIN_DASHBORD_BANNAR_TEXT1} "${SentenceCaseConverter({ string: userDataObj?.userData?.activeUserData?.userData?.fullName })}" ${Constent.BODY_HEADER_ADMIN_DASHBORD_BANNAR_TEXT2}`}</h5>
                        :
                        <h5>{Constent.BODY_HEADER_DASHBORD_BANNAR_TEXT}</h5>
                }
            </div>
            <div className="dashbord-header-calendar-form-group-section">
                <div className="calendar-form-group-item-section">
                    <div className="calendar-form-group-item-today" onClick={() => {
                        setPageState({ ...pageState, calendarDate: moment() });
                    }}>
                        {Constent.TODAY_BTN}
                    </div>
                    <div className="calendar-form-group-item-btn-group-section">
                        <div className="calendar-form-group-item-btn" onClick={() => {
                            setPageState({ ...pageState, calendarDate: pageState?.calendarDate?.clone()?.subtract(1, 'month') });
                        }}>
                            <LeftArrowIcon />
                        </div>
                        <div className="calendar-form-group-item-btn" onClick={() => {
                            setPageState({ ...pageState, calendarDate: pageState?.calendarDate?.clone()?.add(1, 'month') });
                        }}>
                            <RightArrowIcon />
                        </div>
                    </div>
                </div>
                <div className="calendar-form-group-item-section">
                    <div className="col-6 p-0 calendar-form-group-item-date-changer-section">
                        <div className="calendar-form-group-item-date-changer-content">
                            <h6 className='m-0'>Date</h6>
                            <h4 className='m-0'>{pageState?.calendarDate?.format("DD MMM’ YY")}</h4>
                        </div>
                        <div className="calendar-form-group-item-iocn">
                            <CalendarIcon />
                        </div>
                        <div className="calendar-form-group-item-datepicker">
                            <DatePicker selected={pageState?.calendarDate} onChange={date => {
                                setPageState({ ...pageState, calendarDate: moment(date) });
                            }} />
                        </div>
                    </div>
                    <div className="col-xxl-2 col-lg-6 col-md-6 p-0 form-group mb-0 custom-transparent-form-group custom-white-color-form-group">
                        <select className="custom-select bg-white" onChange={(e) => {
                            setPageState({
                                ...pageState, bodyview: e.target.value,
                                toggleIcon: e.target.value === "Month" ?
                                    "Calendar" : pageState?.toggleIcon === "List" ? "List" : "Card"
                            })
                        }}>
                            <option defaultValue="">Month</option>
                            <option value="Day">Day</option>
                            <option value="Month">Month</option>
                        </select>
                        <label htmlFor="exampleSelect1">By</label>
                    </div>
                </div>
            </div>
            <div className="dashbord-header-filter-section">
                <div className="dashbord-header-filter-itme-info">
                    <strong>{`${pageState?.bodyview === "Day" ?
                        (pageState?.calendarViewData[index]?.workOrderList?.length || 0) :
                        pageState?.totalItems} 
                    - ${Constent.HEADER_MENU_ITEM1}`}</strong>
                </div>
                <div className="dashbord-header-filter-item-toggle-btn" onClick={() => {
                    setPageState({
                        ...pageState, toggleIcon: pageState?.bodyview === "Day" ?
                            pageState?.toggleIcon === "List" ? "Card" : "List"
                            :
                            pageState?.toggleIcon === "List" ? "Calendar" : "List"
                    });
                }}>
                    {
                        pageState?.toggleIcon === "Calendar" && <ToggleBtnIcon1 />
                    }
                    {
                        pageState?.toggleIcon === "Card" && <ToggleBtnIcon1 />
                    }
                    {
                        pageState?.toggleIcon === "List" && pageState?.bodyview === "Day" && <ToggleBtnIcon2 />
                    }
                    {
                        pageState?.toggleIcon === "List" && pageState?.bodyview === "Month" && <CalendarIcon fill={"#666666"} />
                    }
                </div>
            </div>
        </div>
    )
}

export default DashbordBodyHeader;
