import React, { useEffect, useState } from "react";
import { Constent } from "../../Assets/Constants/Constent";
import { CalendarIcon, RightArrowIcon, SearchIcon } from "../../Assets/Images/CelcomSvgImages";
import DatePicker from "react-date-picker";
import moment from "moment";
import AdminTrackStockCard from "../../Components/MainBody/Cards/Admin/AdminTrackStockCard";
import { ApiConstant } from "../../Assets/Constants/ApiConstants";
import { TrackStockItemStatusCal } from "../../Components/Common/GenericFunctions";
import { NoDataFoundItemGroup } from "../../Components/Common/CardContent";
import { CardLoader } from "../../Components/Loaders/Loaders";
import InstallerWorkOrderCard from "../../Components/MainBody/Cards/Supervisor/InstallerWorkOrderCard";
import { SuperAdminViewNotes, headers } from "../../Api/ApiCalls";
import { useDispatch, useSelector } from "react-redux";

const ViewScreen = ({
    pageState,
    setPageState,
    data,
    filterList,
    globalState,
    setGlobalState,
    isSearch,
    setIsSearch,
    setViewCardsData,
    ...props
}) => {
    const userDataObj = useSelector((state) => state.userDataInfo);
    const header = headers(userDataObj?.userData?.activeUserData?.authToken);
    const userId = userDataObj?.userData?.activeUserData?.userData?.userId;
    const workOrderDataObj = useSelector((state) => state.workOrderInfo);
    const {
        workOrderId,
        workOrderFromDate,
        workOrderToDate,
        formCalendarNav,
        searchType,
    } = workOrderDataObj;
    const dispatch = useDispatch();
    const [viewState, setViewState] = useState({
        workOrderFromDate: moment().format("YYYY-MM-DD"),
        workOrderToDate: moment().format("YYYY-MM-DD"),
        requestId: ""
    });

    useEffect(() => {
        if (viewState?.workOrderFromDate && viewState?.workOrderToDate) {
            onSearchClicked()
            setViewState((prevFormData) => ({
                ...prevFormData,
                workOrderFromDate: "",
                workOrderToDate: ""

            }))
        }
    }, [])

    function onSearchClicked() {
        console.log(viewState?.workOrderFromDate)
        setIsSearch(true);
        let flag = (viewState?.requestId && viewState?.requestId != "") ||
            (viewState?.workOrderFromDate && viewState?.workOrderFromDate != "")
        let dateFlag = (viewState?.requestId == null || viewState?.requestId == "") &&
            (!viewState?.workOrderFromDate || viewState?.workOrderFromDate == "")
        if (dateFlag) {
            setIsSearch(false);
        } else {
            setIsSearch(true);
        }
        if (flag) {
            let url = "fibre/search/workOrders";
            const date = new Date();
            // const timestamp = (date.getFullYear().toString() + date.getMonth().toString() +
            //     date.getDate().toString() + date.getHours().toString() +
            //     date.getMinutes().toString() + date.getSeconds().toString() + "_" + viewState?.requestId?.toString())

            let rqId = (viewState?.requestId && viewState?.requestId != "")
            let request = {
                request_id: rqId ? viewState?.requestId?.toString() : null,
                user_id: userId,
                start_date: rqId ? null : viewState?.workOrderFromDate,
                end_date: rqId ? null : (viewState?.workOrderToDate ? viewState?.workOrderToDate : moment().format("YYYY-MM-DD")),
            };
            SuperAdminViewNotes(url, request, header)
                .then((res) => {
                    setViewCardsData(res);
                })
                .catch((err) => {
                    setViewCardsData(err);
                })
        }
    }

    function requestIdVal(e) {
        // replace e?.target.value  with userId after confirmation 
        setViewState((prevFormData) => ({
            ...prevFormData,
            requestId: e?.target.value,
            workOrderFromDate: "",
            workOrderToDate: ""

        }))
    }

    // const { className } = TrackStockItemStatusCal(data?.transferStatus);
    return (
        <>
            <div className="workorder-header-search-group-section">
                <div
                    className={`${"col-xxl-12"
                        } col-xl-12 wrapper-workorder-header-search-group`}
                >
                    <div className="workorder-header-search-group-row-section">
                        {Constent.HEADER_MENU_ITEM7}
                    </div>
                    <div className="upload-report-search-group-section">
                        <div className="upload-report-serch-item-section">
                            <div className="inventory-search-group-section">
                                <div className="form-group mb-0 custom-input-form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="RequestId"
                                        value={viewState?.requestId}
                                        placeholder=" "
                                        onChange={(e) => {
                                            requestIdVal(e)
                                        }}
                                    />
                                    <label htmlFor="metrialCodeOrItemName">
                                        {Constent.INVENTORY_CARD_TRACK_STOCK_ITEM_TITLE1}
                                    </label>
                                    <span className="invalid-msg-text">{Constent.INVALID_INPUT}</span>
                                </div>
                            </div>
                            <label className="label">OR</label>
                            <div className="workorder-search-inner-group-section">
                                <div
                                    className={`form-group mb-0 custom-input-form-group ${viewState?.formDateError?.length > 0 &&
                                        "custom-invalid-form-group"
                                        }`}
                                >
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formDate"
                                            placeholder=" "
                                            value={viewState?.workOrderFromDate}
                                            onChange={(e) => {
                                                setViewState({
                                                    ...viewState,
                                                    workOrderFromDate: e.target.value,
                                                });
                                            }}
                                        />
                                        <label htmlFor="formDate">
                                            {Constent.DATE_RANGE_INPUT_ITEM_LABEL1}
                                        </label>
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <CalendarIcon fill={"#333333"} />
                                            </span>
                                            <div className="input-group-append-date-picker-section">
                                                <DatePicker
                                                    selected={moment()}
                                                    onChange={(date) => {
                                                        setViewState({
                                                            ...viewState,
                                                            workOrderFromDate:
                                                                moment(date)?.format("YYYY-MM-DD"),
                                                        });
                                                        setViewState((prevFormData) => ({
                                                            ...prevFormData,
                                                            requestId: ""
                                                        }))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <span className="invalid-msg-text">
                                        {viewState?.formDateError}
                                    </span> */}
                                </div>
                            </div>
                            <div className="workorder-search-inner-group-section">
                                <div
                                    className={`form-group mb-0 custom-input-form-group ${viewState?.toDateError?.length > 0 &&
                                        "custom-invalid-form-group"
                                        }`}
                                >
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="toDate"
                                            placeholder=" "
                                            pattern="[0-9]{4,6}"
                                            value={viewState?.workOrderToDate}
                                            onChange={(e) => {
                                                setViewState({
                                                    ...viewState,
                                                    workOrderToDate: e.target.value,
                                                });
                                            }}
                                        />
                                        <label htmlFor="toDate">
                                            {Constent.DATE_RANGE_INPUT_ITEM_LABEL2}
                                        </label>
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <CalendarIcon fill={"#333333"} />
                                            </span>
                                            <div className="input-group-append-date-picker-section">
                                                <DatePicker
                                                    selected={moment()}
                                                    onChange={(date) => {
                                                        setViewState({
                                                            ...viewState,
                                                            workOrderToDate:
                                                                moment(date)?.format("YYYY-MM-DD"),
                                                        });
                                                        setViewState((prevFormData) => ({
                                                            ...prevFormData,
                                                            requestId: ""
                                                        }))
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    {!isSearch &&
                                        <label>Select from date</label>
                                    }
                                </div>
                            </div>
                            <div
                                className="workorder-search-group-section"
                            // onClick={reportExtractionAPICall}
                            >
                                <div onClick={() => onSearchClicked()}
                                    className="search-btn-section">
                                    <SearchIcon />
                                    {Constent.SEARCH_BTN}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewScreen;
