import "aos/dist/aos.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/@celcomdesign/bootstrap/dist/css/prisma.css";
import "bootstrap";

import "./Assets/CSS/HeaderSideNavMenu.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Aos from "aos";
import { useState, useEffect } from "react";
import { ProtectedRoutes } from "./Auth/ProtectedRoutes";
import { LoginPage } from "./Pages/LoginPage";
import { useSelector, useDispatch } from "react-redux";
import { DashbordPage } from "./Pages/SupervisorViewPages/DashbordPage";
import { InstallerDashbordPage } from "./Pages/InstallerViewPages/InstallerDashbordPage";
import SearchAcivityPage from "./Pages/SupervisorViewPages/SearchAcivityPage";
import InventoryPage from "./Pages/SupervisorViewPages/InventoryPage";
import CartPage from "./Pages/SupervisorViewPages/CartPage";
import InventoryViewStockPage from "./Pages/SupervisorViewPages/InventoryViewStockPage";
import AssignActivityConfirmation from "./Pages/SupervisorViewPages/ConfirmationPages/AssignActivityConfirmation";
import WorkOrderDetailsPage from "./Pages/SupervisorViewPages/WorkOrderDetailsPage";
import PlaceOrderConfirmationPage from "./Pages/SupervisorViewPages/ConfirmationPages/PlaceOrderConfirmationPage";
import { Constent } from "./Assets/Constants/Constent";
import TrackOrderPage from "./Pages/SupervisorViewPages/TrackOrderPage";
import TrackOrderDetailsPage from "./Pages/SupervisorViewPages/TrackOrderDetailsPage";
import ManageInstallerPage from "./Pages/SupervisorViewPages/ManageInstallerPage";
import WorkOrderSummaryDetailsPage from "./Pages/SupervisorViewPages/WorkOrderSummaryDetailsPage";
import SwitchUserPage from "./Pages/SuperAdminViewPages/SwitchUserPage";
import AdminInventoryPage from "./Pages/SuperAdminViewPages/AdminInventoryPage";
import AdminTrackStockRequestDetailsPage from "./Pages/SuperAdminViewPages/AdminTrackStockRequestDetailsPage";
import TrackStockRequestDetailsPage from "./Pages/SupervisorViewPages/TrackStockRequestDetailsPage";
import ManageInstallerConfirmationPage from "./Pages/SupervisorViewPages/ConfirmationPages/ManageInstallerConfirmationPage";
import InstallerSearchAcivityPage from "./Pages/InstallerViewPages/InstallerSearchAcivityPage";
import InstallerWorkOrderDetailsPage from "./Pages/InstallerViewPages/InstallerWorkOrderDetailsPage";
import InstallerUpdateWorkOrderPage from "./Pages/InstallerViewPages/InstallerUpdateWorkOrderPage";
import UpdateWorkOrderStatusConfirmationPage from "./Pages/InstallerViewPages/ConfirmationPages/UpdateWorkOrderStatusConfirmationPage";
import LoginLoaderPage from "./Pages/LoginLoaderPage";
import "react-toastify/dist/ReactToastify.css";
import "./Assets/CSS/Loader.css";
import ApproveStockConfirmationPage from "./Pages/SuperAdminViewPages/ConfirmationPages/ApproveStockConfirmationPage";
import TrakStockAcknowledgePage from "./Pages/SupervisorViewPages/TrakStockAcknowledgePage";
import AcknowledgeConfirmationPage from "./Pages/SupervisorViewPages/ConfirmationPages/AcknowledgeConfirmationPage";
import { headers, SupervisorSummary } from "./Api/ApiCalls";
import { saveSummaryData } from "./ReduxFeature/StateFeatures/WorkOrderDataState";
import { ApiConstant } from "./Assets/Constants/ApiConstants";

import { toast } from "react-toastify";
import moment from "moment";
import NotFoundPage from "./Pages/NotFoundPage";
import ViewWorkOrderReportPage from "./Pages/ViewWorkOrderReportPage";
import WorkOrderViewReportPage from "./Pages/WorkOrderViewReportPage";
import PIDUpload from "./Pages/SuperAdminViewPages/PIDUpload";
import  "./Assets/CSS/PartnerUpload.css";


function App() {
  const userDataObj = useSelector(
    (state) => state.userDataInfo?.userData?.loginUserData
  );
  const activeUserDataObj = useSelector(
    (state) => state.userDataInfo?.userData?.activeUserData
  );
  const workOrderObj = useSelector((state) => state.workOrderInfo);
  const inventoryDataObj = useSelector((state) => state.inventoryDataInfo);
  const installerDataObj = useSelector((state) => state.installerDataInfo);
  const b2cDataObj = useSelector((state) => state.b2cDataInfo);

  const { selectedTrackStockItem, adminTrackStockConfirmMsg } =
    inventoryDataObj;

  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState(userDataObj?.userRole || null);

  // Initialization
  const [globalState, setGlobalState] = useState({
    authLoaderPageState: false,
    userName: "",

    sidebarState: true,
    sideMenuOpenState: false,

    // Work Order Details Page
    selectedWorkOrderId: workOrderObj?.selectedWorkOrderItem?.itemId || "",
    // Assign/ Revert Activity Related States
    sucessfullActivityId: null,
    sucessfullMessage: null,

    // Manage Installer
    createInstallerUserName: "",
    isInstallerCreated: false,
    // Inventory Related State
    inventoryActivePill: Constent.BODY_HEADER_INVENTORY_PILL_ITEM1,
  });
  function SideBarApiCall() {
    //  Header
    const header = headers(activeUserDataObj?.authToken);

    const sidebarRequest = {
      monthYear: moment(workOrderObj?.summaryDate).format("YYYY-MM-DD"),
      userId: activeUserDataObj?.userData?.userId,
      limit: "500",
      offSet: "0",
      summaryDetailsFlag: true,
    };

    SupervisorSummary("/fibre/activity/summary", sidebarRequest, header)
      .then((response) => {
        dispatch(
          saveSummaryData({
            type: ApiConstant.SAVE_SUMMARY_DATA,
            value: response,
          })
        );
      })
      .catch(() => {
        dispatch(
          saveSummaryData({
            type: ApiConstant.SAVE_SUMMARY_DATA,
            value: ApiConstant.EMPTY_SUMMARY_DATA,
          })
        );
        toast.error(ApiConstant.SUMMARY_ERROR_MESSAGE, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 10000,
          theme: "colored",
        });
      });
  }

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  useEffect(() => {
    if (
      (userRole === Constent.SUPER_ROLE || userRole === Constent.ADMIN_ROLE) &&
      activeUserDataObj?.userRole?.length > 0
    ) {
      SideBarApiCall();
    }
  }, [0, activeUserDataObj?.userRole, workOrderObj?.summaryDate, userRole]);

  return (
    <div className="container-fluid p-0 App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <LoginPage
                userRole={userRole}
                setUserRole={setUserRole}
                globalState={globalState}
                setGlobalState={setGlobalState}
              />
            }
          />
          {b2cDataObj?.loginUserName?.length > 0 && (
            <Route
              path="/auth"
              element={
                <LoginLoaderPage
                  userRole={userRole}
                  setGlobalState={setGlobalState}
                  globalState={globalState}
                  setUserRole={setUserRole}
                />
              }
            />
          )}
          {userRole == Constent.ADMIN_ROLE && (
            <Route
              path="/uploadnotes"
              element={
                <PIDUpload
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                />
              }
            />
          )
          }
          {userRole === Constent.SUPER_ROLE ? (
            <Route
              element={
                <ProtectedRoutes
                  loginUserRole={userRole}
                  userRoles={[Constent.SUPER_ROLE]}
                />
              }
            >
              <Route
                path="/dashboard"
                element={
                  <DashbordPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/switchuser"
                element={
                  <SwitchUserPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />

              {/* Work Order Related */}
              <Route
                path="/workorder"
                element={
                  <WorkOrderViewReportPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/viewworkorderreport"
                element={
                  <ViewWorkOrderReportPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              {workOrderObj?.selectedWorkOrderItem !== null &&
                workOrderObj?.selectedWorkOrderItem?.itemId?.length > 0 && (
                  <Route>
                    <Route
                      path="/workorderdetails"
                      element={
                        <WorkOrderDetailsPage
                          globalState={globalState}
                          setGlobalState={setGlobalState}
                        />
                      }
                    />
                    <Route
                      path="/workorder/confirm"
                      element={
                        <AssignActivityConfirmation
                          globalState={globalState}
                          setGlobalState={setGlobalState}
                        />
                      }
                    />
                  </Route>
                )}
              <Route
                path="/workordersummary"
                element={
                  <WorkOrderSummaryDetailsPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />

              {/* Work Order Related */}
              <Route
                path="/trackorder"
                element={
                  <TrackOrderPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/trackorderdetails"
                element={
                  <TrackOrderDetailsPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />

              {/* Manage Installer Related */}
              <Route
                path="/manageinstaller"
                element={
                  <ManageInstallerPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              {installerDataObj?.addInstallerResponse !== null && (
                <Route
                  path="/manageinstaller/confirm"
                  element={
                    <ManageInstallerConfirmationPage
                      installerDataObj={installerDataObj}
                      globalState={globalState}
                      setGlobalState={setGlobalState}
                    />
                  }
                />
              )}

              {/* Inventory Related */}
              <Route
                path="/inventory"
                element={
                  <InventoryPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/inventory/view"
                element={
                  <InventoryViewStockPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/inventory/trackstock/acknowledge"
                element={
                  <TrakStockAcknowledgePage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              {selectedTrackStockItem !== null && (
                <Route>
                  <Route
                    path="/inventory/trackrequestdetails"
                    element={
                      <TrackStockRequestDetailsPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                  <Route
                    path="/inventory/trackstock/acknowledge/confirm"
                    element={
                      <AcknowledgeConfirmationPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                </Route>
              )}

              <Route
                path="/cart"
                element={
                  <CartPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/cart/confirm"
                element={
                  <PlaceOrderConfirmationPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
            </Route>
          ) : userRole === Constent.INSTALLER_ROLE ? (
            <Route
              element={
                <ProtectedRoutes
                  loginUserRole={userRole}
                  userRoles={[Constent.INSTALLER_ROLE]}
                />
              }
            >
              <Route
                path="/dashboard"
                element={
                  <InstallerDashbordPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/workorder"
                element={
                  <InstallerSearchAcivityPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/workorderdetails"
                element={
                  <InstallerWorkOrderDetailsPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/workorder/statusupdate"
                element={
                  <InstallerUpdateWorkOrderPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/workorder/statusupdate/confirm"
                element={
                  <UpdateWorkOrderStatusConfirmationPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
            </Route>
          ) : userRole === Constent.ADMIN_ROLE ? (
            <Route
              element={
                <ProtectedRoutes
                  loginUserRole={userRole}
                  userRoles={[Constent.ADMIN_ROLE]}
                />
              }
            >
              <Route
                path="/switchuser"
                element={
                  <SwitchUserPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/dashboard"
                element={
                  <DashbordPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />

              {/* Work Order Related */}
              <Route
                path="/workorder"
                element={
                  <WorkOrderViewReportPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/workorder/confirm"
                element={
                  <AssignActivityConfirmation
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/workorderdetails"
                element={
                  <WorkOrderDetailsPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/workordersummary"
                element={
                  <WorkOrderSummaryDetailsPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />
              <Route
                path="/viewworkorderreport"
                element={
                  <ViewWorkOrderReportPage
                    globalState={globalState}
                    setGlobalState={setGlobalState}
                  />
                }
              />

              {activeUserDataObj?.userRole === Constent.SUPER_ROLE && (
                <Route>
                  <Route
                    path="/viewworkorderreport"
                    element={
                      <ViewWorkOrderReportPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />

                  {/* Tack Order Related */}
                  <Route
                    path="/trackorder"
                    element={
                      <TrackOrderPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                  <Route
                    path="/trackorderdetails"
                    element={
                      <TrackOrderDetailsPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />

                  {/* Manage Installer Related */}
                  <Route
                    path="/manageinstaller"
                    element={
                      <ManageInstallerPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                  {installerDataObj?.addInstallerResponse !== null && (
                    <Route
                      path="/manageinstaller/confirm"
                      element={
                        <ManageInstallerConfirmationPage
                          installerDataObj={installerDataObj}
                          globalState={globalState}
                          setGlobalState={setGlobalState}
                        />
                      }
                    />
                  )}

                  {/* ------------------- Inventory Related ----------------- */}
                  <Route
                    path="/inventory"
                    element={
                      <InventoryPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                  <Route
                    path="/inventory/view"
                    element={
                      <InventoryViewStockPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                  <Route
                    path="/inventory/trackrequestdetails"
                    element={
                      <TrackStockRequestDetailsPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                  <Route
                    path="/inventory/trackstock/acknowledge"
                    element={
                      <TrakStockAcknowledgePage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                  <Route
                    path="/inventory/trackstock/acknowledge/confirm"
                    element={
                      <AcknowledgeConfirmationPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />

                  <Route
                    path="/cart"
                    element={
                      <CartPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                  <Route
                    path="/cart/confirm"
                    element={
                      <PlaceOrderConfirmationPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                </Route>
              )}
              {activeUserDataObj?.userRole === Constent.ADMIN_ROLE && (
                <Route>
                  <Route
                    path="/inventory"
                    element={
                      <AdminInventoryPage
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                      />
                    }
                  />
                  {selectedTrackStockItem !== null && (
                    <Route>
                      <Route
                        path="/inventory/trackrequestdetails"
                        element={
                          <AdminTrackStockRequestDetailsPage
                            globalState={globalState}
                            setGlobalState={setGlobalState}
                          />
                        }
                      />
                      {adminTrackStockConfirmMsg !== null && (
                        <Route
                          path="/inventory/confirm"
                          element={
                            <ApproveStockConfirmationPage
                              globalState={globalState}
                              setGlobalState={setGlobalState}
                            />
                          }
                        />
                      )}
                    </Route>
                  )}
                </Route>
              )}
            </Route>
          ) : (
            <></>
          )}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
